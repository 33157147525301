/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useSessionState } from '@beelineloans/cx-library/src/state/stateProvider';
import Template from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import { ReferralHeader } from '@beelineloans/cx-library/src/components/layout/headers';
import { HeaderWithButtonAndVideo } from '@beelineloans/cx-library/src/components/layout/headersv2';
import VideoModal from '@beelineloans/cx-library/src/components/modals/VideoModal';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import {
  NumberedListWithImage,
  TextWithChecklistImg,
  PlantAndCoffee,
  Celebrate,
  ReviewsWithSocial,
  SocialProof,
  LoanGuideConvo,
  TextAndImageV2
} from '@beelineloans/cx-library/src/components/layout/panels';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { Theme } from '@beelineloans/cx-library/src/theme/default';
import { Breakpoint, Legal, Paragraph, Span } from '@beelineloans/cx-library/src/components/typography';
import { Button, ButtonText } from '@beelineloans/cx-library/src/components/buttons';
import { getStars } from '@beelineloans/cx-library/src/components/typography/Review';
import SiteCopy from '@beelineloans/cx-library/src/utils/site-copy';
import StessaLogo from '@beelineloans/cx-library/src/images/logos/stessa.png';
import DocHeart from '@beelineloans/cx-library/src/images/docheart.png';

import { applyEndpoint } from '@beelineloans/cx-library/src/utils/helpers';
import Container from '@beelineloans/cx-library/src/components/layout/ContainerV2';
import KaliBg from '../../../shared/images/kali-bg.jpg';

const TemplateWrap = styled.div`
  @media only screen and (${Theme.mediaBreakpoints.tablet}) {
    #header-child-left {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`;

const KaliParagraph = styled(Paragraph)`
  padding-bottom: 35px;
`;

const KaliPanel = styled(Container)`
  background: url(${KaliBg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;

  @media only screen and (${Theme.mediaBreakpoints.tablet}) {
    background-size: cover;
    background-position: center center;
  }

  .container-inner {
    padding-top: 60vw;
    padding-bottom: 80px;
    @media only screen and (${Theme.mediaBreakpoints.tablet}) {
      padding-top: 300px;
      padding-bottom: 150px;
      height: 800px;
    }
    display: flex;

    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    align-self: stretch;

    ${Paragraph} {
      color: ${(props) => props.theme.colours.text.dark};
      font-size: 30px;
      line-height: 36px;
      @media only screen and (${Theme.mediaBreakpoints.tablet}) {
        color: ${(props) => props.theme.colours.text.light};
        font-size: 35px;
        line-height: 40px;
      }
      margin-bottom: 0;
      font-style: normal;
      font-weight: 500;
    }
  }
`;

const KaliPlayBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  ${Span} {
    color: ${(props) => props.theme.colours.text.dark};
    font-size: 15px;
    line-height: 20px;
    @media only screen and (${Theme.mediaBreakpoints.tablet}) {
      color: ${(props) => props.theme.colours.text.light};
      font-size: 25px;
      line-height: 30px;
    }
    font-weight: 400;
    line-height: 30px;
  }
`;

const KaliStars = styled.div``;

const KalliPlayIcon = styled.svg`
  stroke: ${(props) => props.theme.colours.text.dark};
  rect {
    stroke: ${(props) => props.theme.colours.text.dark};
  }
  path {
    fill: ${(props) => props.theme.colours.text.dark};
  }

  @media only screen and (${Theme.mediaBreakpoints.tablet}) {
    stroke: ${(props) => props.theme.colours.text.light};
    rect {
      stroke: ${(props) => props.theme.colours.text.light};
    }
    path {
      fill: ${(props) => props.theme.colours.text.light};
    }
  }
`;

const KalliPlayIconButton = styled(ButtonText)`
  line-height: 0;
`;

const GreenLight = styled(TextWithChecklistImg)`
  p:last-of-type {
    color: rgba(0, 0, 76, 0.5);
  }
`;

// const ChatTemplate = styled(NavOnlyTemplate)`
//   transition: all 0.3s ease-in-out;

//   @media only screen and (max-width: 767px) {
//     ${(props) =>
//       props.$fixed &&
//       css`
//         position: fixed;
//         top: 0;
//         left: 0;
//         width: 100%;
//         z-index: 20;
//         box-shadow: 0 0px 16px rgba(0, 0, 76, 15%);
//       `};
//   }
// `;
const IndexPage = ({ location, referral }) => {
  const [, dispatch] = useSessionState();
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const { state } = location;

  const realtorName = state ? state.realtorName : null;
  const realtorFirmName = state ? (state.realtorFirmName || '').toLowerCase() : null;

  const handleVideoModalClosed = () => {
    setVideoModalOpen(false);
  };

  const handleVideoModalOpen = () => {
    setVideoModalOpen(true);
  };

  const askButtonClick = () => {
    dispatch({
      type: CONST.ACTIONS.CONTACT_FORM_OPEN,
      contactForm: {
        open: true,
        type: 'contact'
      }
    });
  };

  let headlineBlock = <Breakpoint smallBreakpoint={<>Get a home loan from your sofa</>} largeBreakpoint={<>Get a home loan from your sofa</>} />;

  let subHeadline = referral ? (
    ''
  ) : (
    <>
      Apply in less than 10 minutes
      <br />
      More loan types so you’re more likely to qualify
      <br />
      Fast, stress-free closing in as little as 14 days
    </>
  );
  // let chatButtonLink;
  let adjustedBGColour;
  let templateProps;
  let headerProps;
  let celebrateProps = {
    heading: 'Get the loan out of the way and get to your happy place.',
    children: (
      <>
        <Paragraph>So where is that? </Paragraph>
        <Paragraph>In your new home, snuggling on the sofa and finding your new local coffee hangout? </Paragraph>
        <Paragraph>Breathing a sigh of relief after an easy cash-out refi frees you up?</Paragraph>
        <Paragraph>Kicking back watching your portfolio expand on a Tuesday morning while you cheers yourself at the movie theater?</Paragraph>
      </>
    )
  };

  if (realtorName) {
    // TODO: all this should be stored in with the realtor details, not hard coded

    if (realtorFirmName === 'awning') {
      // chatButtonLink = CONST.LINKS.EXTERNAL.CALENDLY;
    } else if (realtorFirmName === 'stessa') {
      headlineBlock = 'Get a home loan from your sofa.';
      subHeadline = 'Skip the painful application. Lock in a low rate in 15 mins and watch your cash flow increase in Stessa.';
      adjustedBGColour = {
        backgroundColor: Theme.colours.background.tanLight
      };
      templateProps = {
        headerComponent: ReferralHeader
      };
      headerProps = {
        referrerLogo: StessaLogo
      };
      celebrateProps = {
        heading: 'Can we get a ‘hell yeah&apos; for financial freedom?',
        children: (
          <>
            <Paragraph>
              There&apos;s nothing like the feeling of taking steps towards financial freedom — and we get it. We know the loan itself is less important than taking you where you
              want to be.
            </Paragraph>
            <Paragraph>And where&apos;s that?</Paragraph>
            <Paragraph>Kicking back watching your portfolio expand on a Tuesday morning while you cheers yourself at the cinema.</Paragraph>
            <Paragraph>So, let&apos;s get the loan out of the way — so you can watch that rent roll in.</Paragraph>
          </>
        )
      };
    } else if (realtorFirmName === 'J. Trainor & Company' || realtorFirmName === 'Halo') {
      // keep defaults
    } else {
      subHeadline = `Apply today and receive a free appraisal from your realtor, ${realtorName}`;
    }
  }

  const numberedList = [
    {
      number: 1,
      noBorder: false,
      ...SiteCopy.numberedList.ApplyingIsEffortlessHome
    },
    {
      number: 2,
      noBorder: false,
      ...SiteCopy.numberedList.MoreOptionsToQualify
    },
    {
      number: 3,
      noBorder: false,
      ...SiteCopy.numberedList.CertaintyUpfront
    },
    {
      number: 4,
      noBorder: true,
      ...SiteCopy.numberedList.FastStressFree
    }
  ];
  // const templateOptions = { options: { background: Theme.colours.background.nude }, ...headerProps };

  return (
    <TemplateWrap>
      {/* <ChatTemplate sideNavLinksComponent={false} {...templateProps} headerProps={templateOptions} $fixed={isHeaderFixed} $realtorFirmName={realtorFirmName}> */}
      <Template
        sideNavLinksComponent={false}
        headline={headlineBlock}
        subHeadline={subHeadline}
        $realtorFirmName={realtorFirmName}
        headerComponent={HeaderWithButtonAndVideo}
        {...templateProps}
        headerProps={{
          options: { headerRatings: true, voomly: true },
          ...headerProps
        }}
      >
        <SEO
          path={CONST.LINKS.MAIN.HOME}
          title="Get a home loan from your sofa"
          description="Skip the gruelling application. Forget the flakey pre-approval. Get the best rates and a Purchase-Ready Approval you can rely on."
          antiFlicker
        />
        {/* <ChatHeader onHeaderFixed={setIsHeaderFixed} /> */}
        <SocialProof dark />
        <NumberedListWithImage
          sideHeading={
            <>
              Home loans like
              <br />
              never before.
            </>
          }
          items={numberedList}
        />
        {/* <iframe
          src="https://embed.voomly.com/embed/assets/embed.html?videoId=qhfmCBeI3FzDNB8biA_d2WNcxXdYAFtucsmTa4hoBjkTOZIiY&amp;videoRatio=1.777778&amp;type=v&amp;skinColor=rgba(255%2C65%2C96%2C1)"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen=""
          width="100%"
          height="100%"
          title="video-iframe"
        /> */}
        <KaliPanel>
          <KaliParagraph>
            My income can change a lot —<br />
            that’s the nature of gig work.
          </KaliParagraph>
          <KaliStars>{getStars(5)}</KaliStars>
          <KaliPlayBlock>
            <ButtonText onClick={handleVideoModalOpen}>
              <Span>Watch Kalli’s story</Span>
            </ButtonText>
            <KalliPlayIconButton onClick={handleVideoModalOpen}>
              <KalliPlayIcon xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                <rect x="1.5" y="1.5" width="45" height="45" rx="22.5" stroke="white" strokeWidth="3" />
                <path d="M32.2275 23.9999L18.5133 32.2284L18.5133 15.7713L32.2275 23.9999Z" fill="#FDFCF9" />
              </KalliPlayIcon>
            </KalliPlayIconButton>
          </KaliPlayBlock>
        </KaliPanel>
        <VideoModal open={videoModalOpen} closeCallback={handleVideoModalClosed} videoId={CONST.VIDEOS.VOOMLY.KALLI} voomly />
        <ReviewsWithSocial
          title="Lender love letters."
          text={<></>}
          googleRating={process.env.GATSBY_GOOGLE_REVIEW_NUM}
          bbbRating={process.env.GATSBY_BBB_REVIEW_NUM}
          {...adjustedBGColour}
        />
        <TextAndImageV2
          h2={<>A bigger bag of loan options means we can help more people.</>}
          image={{
            src: DocHeart,
            mobileWidth: '315px',
            title: 'A bigger bag of loan options means we can help more people. ',
            desktopWidth: '483px',
            alignment: 'center'
          }}
          left
        >
          <Paragraph>
            If your situation is easy, we can give you loan options in minutes.
            <br />
            <br />
            But if your situation isn&apos;t so easy, or if you&apos;re one of 73 million gig economy workers or a property investor, other lenders will put you in the &apos;too
            hard&apos; basket.
            <br />
            <br />
            Beeline&apos;s wider variety of loan options means you&apos;re less likely to waste time and more likely to find a solution.
            <br />
            <br />
            If you&apos;re self employed with irregular income we&apos;ve got bank statement loans. If you&apos;re investing, DSCR loans are ideal to qualify with rental income
            instead of yours.
            <br />
            <br />
            Why can&apos;t it be easy for everyone?
          </Paragraph>
          <br />
          <Button to={applyEndpoint}>Apply now</Button>
        </TextAndImageV2>
        <GreenLight heading="Get the green light, not a guesstimate." backgroundColor={Theme.colours.background.yellow}>
          <Paragraph>
            Beeline pre-approvals are more reliable than typical pre-approvals. They&apos;re 95% likely to close* — so you&apos;ll be truly ready to purchase.
            <br />
            <br />
            Take it house shopping knowing it&apos;s not going to disappoint later.
            <br />
            <br />
            Yours is waiting for you, rates and all — so you&apos;ll be making offers like a boss.
          </Paragraph>
          <Legal>*Not all products and options are available In all states. Terms are subject to change without notice. Pre-approval will depend by individual situation.</Legal>
        </GreenLight>
        <LoanGuideConvo>{null}</LoanGuideConvo>
        <Celebrate {...celebrateProps} />
        <PlantAndCoffee heading="Not sure if you're ready? Chat to a Loan Guide." chatButtonLink={null} chatButtonClick={askButtonClick} />
      </Template>
    </TemplateWrap>
  );
};

IndexPage.propTypes = {
  location: PropTypes.object,
  referral: PropTypes.bool
};

IndexPage.defaultProps = {
  referral: false
};

export default IndexPage;
